<div class="parent">
    <div>
        <div class="card-body">
            <div class="intial-amb-form-parent">
                <form class="container" [formGroup]="intialAmbulanceForm" >
                    <div>
                        <label>Name</label>
                        <input type="text" class="form-control"  formControlName="userName" />
                    </div>
                    <div class="mt-3">
                        <label>Phone Number</label>
                        <input type="tel" class="form-control" formControlName="userPhone" />
                    </div>
                    <div class="mt-3">
                        <label>PickUp Address</label>
                        <input type="text" class="form-control" formControlName="pickupPoint" />
                    </div>
                    <div class="mt-3">
                        <label>Drop Address</label>
                        <input type="text" class="form-control" formControlName="dropPoint" />
                    </div>
                </form>
                <div class="text-end">
                    <button class="btn anvayaabutton-secondary" (click)="submitVendorToRequest()"> submit </button>
                </div>
            </div>
        </div>
    </div>
</div>