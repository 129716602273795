import { Component } from '@angular/core';
import { UsersService } from 'src/app/users.service';
import { ActivatedRoute, Router, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, FormArray } from '@angular/forms';
// import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Location } from '@angular/common'
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-ambulanceintegrations',
  templateUrl: './ambulanceintegrations.component.html',
  styleUrls: ['./ambulanceintegrations.component.css']
})
export class AmbulanceintegrationsComponent {

  intialAmbulanceForm:UntypedFormGroup
  constructor(private spinner:NgxSpinnerService,private location: Location,   
    private userservice: UsersService, private route: Router, private router: ActivatedRoute, private formBuilder: UntypedFormBuilder) { }

    RequestID:any
    requestdetail:any = {}
    CustomerDetails:any
    ngOnInit(): void {
      this.RequestID = this.router.snapshot.queryParamMap.get('RequestID');
     this.intialAmbulance()  //form function  
     this.requestDetails() // requestDetails
    }

    intialAmbulance(){

      this.intialAmbulanceForm = this.formBuilder.group({
          "userName":[''],
          "userPhone":[''],
          "pickupPoint":[''],
          "dropPoint":['']
      })
    }
       

    requestDetails(){
      this.userservice.viewRequest(this.RequestID).subscribe(async (data: any) => {
        this.spinner.hide();
        this.requestdetail = data.data // assin=gning the fetched request details
        console.log("requestdetail",this.requestdetail)
          this.CustomerDetails = this.requestdetail.customerDetails.Beneficiaries.filter((data:any)=>{
                if(this.requestdetail.CustID == data.CustID){
                   return data
                }
          })

          console.log("lalalal",this.CustomerDetails)
      },
        (error) => {
          this.spinner.hide();
          alert(error.data.data);
        },
      );
    }
      

    submitIntialForm(){
         console.log(this.intialAmbulanceForm.value)
    }

    submitVendorToRequest(){


      const Vendor:any = {
        // "RequestID":this.reqId,
        // "PartnerID":this.SelectedPartner
      }
  
  
      this.userservice.AddVendorForRequest(Vendor).subscribe((VendorsList:any) => {
           alert(VendorsList.message)
          this.route.navigate(['/Dashboard/Task/MyTask'])
          if (VendorsList.code == "S002") {
        } if (VendorsList.code == "ND01") {
         // this.route.navigate(['/Dashboard/Task/MyTask'])
        }
      }, (error) => {
        alert(error.error.message);
      })
    }

}
